export enum COURSE_KEYS {
  LIST = "list-courses",
  DETAIL = "get-course-details",
  LIST_BY_RANK = "list-by-rank",
  SEARCH = "search-courses",
  CATEGORY_LIST = "category-list",
}

export enum JOB_KEYS {
  LIST = "list-jobs",
  DETAIL = "get-job-details",
  LIST_BY_RANK = "list-by-rank",
  SEARCH = "search-jobs",
  APPLICATION = "get-job-application",
}

export enum HEADER_KEYS {
  GET_HEADER = "get-headers",
}
