import { useCallback, useRef, useState } from "react";
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";

/* API */
import { Handlers } from "./handler";

/* Keys */
import { COURSE_KEYS } from "../keys";

/* Types */
import type { HookOpts, ResponseType } from "../types";
import type {
  CoursePayload,
  CourseRes,
  CourseModulePayload as ModulePayload,
  CourseModuleResponseType as ModuleRT,
  listQueryParams,
  CourseListResponseType,
  GetCourseDetailsRes,
  NameAvailabilityType,
  NameCheckRes,
  DeleteCourseRes,
  ListByRankRes,
  SearchCourseRes,
  CategoryListRes,
} from "./types";
import { useDebounce } from "@src/hooks";

export const useCourseCreate = () => {
  const courseMut = useMutation<CourseRes, ResponseType, CoursePayload>({
    mutationFn: Handlers.CreateCourse,
  });

  const moduleMut = useMutation<ModuleRT, ResponseType, ModulePayload>({
    mutationFn: Handlers.CreateCourseModule,
  });

  return { courseMutation: courseMut, moduleMutation: moduleMut };
};

export const useCourseList = (
  defaultOptions?: Partial<listQueryParams>,
  opts: HookOpts = {},
) => {
  const [params, setParams] = useState<listQueryParams>({
    sortBy: "createdAt",
    sortOrder: "DESC",
    page: 1,
    size: 10,
    allowDraft: false,
    ...defaultOptions,
  });

  const handleChange = useCallback(
    (newParams: listQueryParams) => {
      setParams({ ...params, ...newParams });
    },
    [params],
  );

  const query = useSuspenseQuery<CourseListResponseType, ResponseType>({
    queryKey: [COURSE_KEYS.LIST, params, opts],
    queryFn: () => Handlers.ListCourses(params, opts),
  });

  return [query, handleChange, params] as const;
};

export const useGetCourseDetails = (defaultSlug: string = "") => {
  const [slug, setSlug] = useState(defaultSlug);

  const [tokens, setTokens] = useState({
    refreshToken: "",
    token: "",
  });

  const query = useSuspenseQuery<GetCourseDetailsRes, ResponseType>({
    queryKey: [COURSE_KEYS.DETAIL, slug, tokens],
    queryFn: () => Handlers.GetCourseDetails(slug, tokens),
  });

  return [query, setSlug, setTokens] as const;
};

export const useCourseUpdate = (defaultValue = "") => {
  const [slug, setSlug] = useState(defaultValue);
  const courseMut = useMutation<CourseRes, ResponseType, CoursePayload>({
    mutationFn: (payload) => Handlers.UpdateCourse(slug, payload),
  });

  const moduleMut = useMutation<boolean, ResponseType, ModulePayload>({
    mutationFn: Handlers.UpdateCourseModule,
  });

  return { courseMutation: courseMut, moduleMutation: moduleMut, setSlug };
};

export const useCheckNameAvailability = (type: NameAvailabilityType) => {
  const name = useRef<string>("");
  const mut = useMutation<NameCheckRes, ResponseType, void>({
    mutationFn: () =>
      Handlers.checkNameAvailability({ name: name.current }, type),
  });

  return [mut, name] as const;
};

export const useCourseDelete = (defaultValue = "") => {
  const [slug, setSlug] = useState(defaultValue);

  const mut = useMutation<DeleteCourseRes, ResponseType, void>({
    mutationFn: () => Handlers.deleteCourse(slug),
  });

  return [mut, setSlug] as const;
};

export const useListCourseByRank = (allowDraft = false) => {
  const query = useSuspenseQuery<ListByRankRes, ResponseType>({
    queryKey: [COURSE_KEYS.LIST_BY_RANK, allowDraft],
    queryFn: () => Handlers.listByRank(allowDraft),
  });

  return [query];
};

export const useCourseSearch = (defaultQuery = "") => {
  const [search, setSearch] = useState(defaultQuery);
  const debouncedSearch = useDebounce(search, 500);

  const searchQuery = useQuery<SearchCourseRes, ResponseType>({
    queryKey: [COURSE_KEYS.SEARCH, debouncedSearch],
    queryFn: () => Handlers.searchCourses(debouncedSearch),
  });

  return [searchQuery, setSearch] as const;
};

export const useListCategory = () => {
  const query = useSuspenseQuery<CategoryListRes, ResponseType>({
    queryKey: [COURSE_KEYS.CATEGORY_LIST],
    queryFn: Handlers.categoryList,
  });

  return [query] as const;
};
