import { ObjectFilterEmpty } from "@utils/utils";
import { AxiosWrapper } from "../axios";
import { ENDPOINTS } from "../endpoints";

/* Types */
import type { HookOpts, Tokens } from "../types";

/* Types */
import type {
  CourseModuleResponseType,
  CoursePayload,
  CourseModulePayload,
  CourseRes,
  listQueryParams,
  CourseListResponseType,
  NameCheckRes,
  GetCourseDetailsRes,
  CheckNamePayload,
  NameAvailabilityType,
  DeleteCourseRes,
  ListByRankRes,
  SearchCourseRes,
  CategoryListRes,
} from "./types";

const CreateCourse = async (payload: CoursePayload) => {
  return await AxiosWrapper<CourseRes, CoursePayload>({
    method: "POST",
    url: ENDPOINTS.createCourse,
    isProtectedRoute: true,
    body: payload,
  });
};

const CreateCourseModule = async (payload: CourseModulePayload) => {
  return await AxiosWrapper<CourseModuleResponseType, CourseModulePayload>({
    method: "POST",
    url: ENDPOINTS.createCourseModule,
    isProtectedRoute: true,
    body: payload,
  });
};

const ListCourses = async (
  query: listQueryParams,
  opts: HookOpts,
): Promise<CourseListResponseType> => {
  const { isManagement, refreshToken, token } = opts;

  const { page, size, ...optionalParams } = query;

  const params = { page, size } as Required<Record<string, any>>;

  // Optional Params
  optionalParams.categories = query.categories
    ? (query.categories.join(",") as any)
    : undefined;
  const filteredParams = ObjectFilterEmpty(optionalParams);

  return await AxiosWrapper({
    method: "GET",
    url: isManagement ? ENDPOINTS.adminListCourses : ENDPOINTS.listCourses,
    params: { ...params, ...filteredParams },
    isProtectedRoute: isManagement,
    token,
    refreshToken,
  });
};

const GetCourseDetails = async (
  slug: string,
  tokens: Tokens,
): Promise<GetCourseDetailsRes> => {
  return await AxiosWrapper({
    method: "GET",
    url: ENDPOINTS.getCourseDetails.replace(":slug", slug),
    // isProtectedRoute: true,
    // ...(tokens && { ...tokens }),
  });
};

const UpdateCourse = async (slug: string, payload: CoursePayload) => {
  return await AxiosWrapper<CourseRes, CoursePayload>({
    method: "PATCH",
    url: ENDPOINTS.updateCourse.replace(":slug", slug),
    body: payload,
    isProtectedRoute: true,
  });
};

const UpdateCourseModule = async (payload: CourseModulePayload) => {
  return await AxiosWrapper<boolean, CourseModulePayload>({
    method: "PATCH",
    url: ENDPOINTS.updateCourseModule,
    body: payload,
    isProtectedRoute: true,
  });
};

const checkNameAvailability = async (
  payload: CheckNamePayload,
  type: NameAvailabilityType,
) => {
  return await AxiosWrapper<NameCheckRes, CheckNamePayload>({
    method: "POST",
    params: { type },
    url: ENDPOINTS.checkNameAvailability,
    body: payload,
  });
};

const deleteCourse = async (slug: string) => {
  return await AxiosWrapper<DeleteCourseRes, void>({
    method: "DELETE",
    isProtectedRoute: true,
    url: ENDPOINTS.deleteCourse.replace(":slug", slug),
  });
};

const listByRank = async (allowDraft = false) => {
  return await AxiosWrapper<ListByRankRes, void>({
    method: "GET",
    url: ENDPOINTS.listByRank,
    params: { allowDraft: allowDraft ? 1 : 0 },
  });
};

const searchCourses = async (query: string) => {
  return await AxiosWrapper<SearchCourseRes, void>({
    method: "GET",
    url: ENDPOINTS.searchCourse,
    params: { q: query },
  });
};

const categoryList = async () => {
  return await AxiosWrapper<CategoryListRes, void>({
    method: "GET",
    url: ENDPOINTS.getCategories,
  });
};

export const Handlers = {
  CreateCourse,
  CreateCourseModule,
  ListCourses,
  GetCourseDetails,
  UpdateCourse,
  listByRank,
  deleteCourse,
  UpdateCourseModule,
  searchCourses,
  checkNameAvailability,
  categoryList,
};
